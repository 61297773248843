<template>
  <span>
    <i :class="clazz" :style="style" />
    {{ text }}
  </span>
</template>
<script>
export default {
  props: ['isOk'],
  computed: {
    clazz() {
      return {
        'mr-1': true,
        fa: true,
        'fa-check-circle': this.isOk,
        'fa-exclamation-triangle': !this.isOk,
      }
    },
    style() {
      return `color: ${this.isOk ? '#8bc34a' : '#ef5350'};`
    },
    text() {
      return this.isOk ? 'OK' : 'NG'
    },
  },
}
</script>
