<template>
  <div>
    <h1 :class="$style.pageTitle">{{ pageTitle }}</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <a-form class="no-print" layout="inline" style="margin-bottom: 20px;">
              対象支店：
              <a-select
                show-search
                v-model="branchId"
                style="width: 30%; margin-right: 20px;"
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in branches"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              対象日：
              <a-button @click="yesterday()" style="margin-right: 3px;">前日</a-button>
              <a-button @click="today()" style="margin-right: 3px;">今日</a-button>
              <a-button @click="tomorrow()" style="margin-right: 7px;">翌日</a-button>
              <a-config-provider :locale="locale">
                <a-date-picker placeholder="対象日" v-model="targetDate" />
              </a-config-provider>
              <a-button type="primary" @click="refreshList" style="margin-left: 10px;" :class="$style.filledBtn">取得</a-button>
              <span class="float-right">
                <router-link :to="{ path: '/inspection/summary', query: { branchId, date: gotDateHyphen }}">
                  <a-button type="primary" :class="$style.filledBtn">月別点検状況</a-button>
                </router-link>
              </span>
            </a-form>
            <hr class="mt-4">
          </div><!-- /.card-body (フィルタ) -->

          <div class="card-body">
            <div v-if="loading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-else>
              <h4><span class="font-weight-bold">{{ gotBranchName }}</span> の運行前点検</h4>

              <div class="mt-4 clearfix">
                <div class="float-left">
                  <template v-if="isConfirmedPage">
                    <router-link
                      :to="{ path: '/inspection/unconfirmed', query: { branchId, date: gotDateHyphen }}"
                    >
                      <a-button type="primary" :class="$style.filledBtn">
                        未確認一覧
                      </a-button>
                    </router-link>
                  </template>
                  <template v-else>
                    <a-button
                      v-if="isMaster"
                      type="primary"
                      @click="turnSelectedInspectionsConfirmed"
                      class="mr-2"
                      :class="$style.filledBtn"
                      :disabled="isConfirmationButtonDisabled">
                      確認済みにする
                    </a-button>
                    <router-link :to="{ path: '/inspection/confirmed', query: { branchId, date: gotDateHyphen }}">
                      <a-button type="primary" :class="$style.filledBtn">
                        確認履歴
                      </a-button>
                    </router-link>
                  </template>
                </div>
                <div v-if="isMaster" class="float-right">
                  <a-button type="primary" @click="onCreateInspection" :class="$style.filledBtn">作成</a-button>
                </div>
              </div>

              <a-table
                :columns="columns"
                :data-source="inspectionDataList"
                :pagination="false"
                :bordered="false"
                rowKey="id"
                :rowClassName="
                  (r, i) => i % 2 === 0 && r.manual === 1 ? $style.manual_row + ' ' + $style.stripe : i % 2 === 0 ? $style.stripe : r.manual === 1 ? $style.manual_row : ''
                "
                :scroll="{x: true}"
                class="mt-4"
                :class="$style.aTable"
              >
                <span slot="confirmTitle">
                  <a-checkbox :indeterminate="isConfIndeterminate" :checked="isConfAllChecked" @change="onChangeConfirmAll">
                    確認
                  </a-checkbox>
                </span>
                <span slot="driverTitle">
                  <div class="mb-1">運転手名</div>
                  <div>(車両名)</div>
                </span>
                <span slot="executionTitle">
                  <div class="mb-1">実施</div>
                  <div>有無</div>
                </span>
                <span slot="answerTitle">
                  <div class="mb-1">車両</div>
                  <div>点検</div>
                </span>

                <span v-if="!isConfirmedPage" slot="confirm" slot-scope="id, record">
                  <span v-if="record.confirm === 1">確認済</span>
                  <div v-else>
                    <a-checkbox @change="onChangeConfirmRow($event, record.id)" :checked="isConfRowChecked(record.id)" />
                  </div>
                </span>
                <span slot="branch_name" slot-scope="id, record">
                  {{ branchNameByVehicleId(record.vehicle_id) }}
                </span>
                <span slot="driver" slot-scope="id, record">
                  {{ record.user_name }} ({{ record.vehicle_name }})
                </span>
                <span slot="execution" slot-scope="id, record">
                  {{ record.execution === 1 ? '済' : '無' }}
                </span>
                <span slot="answer" slot-scope="id, record">
                  <check-result v-if="record.execution" :is-ok="isAnswerAllOk(record.answer)" />
                </span>
                <span slot="overwork" slot-scope="id, record">
                  <check-result v-if="record.execution" :is-ok="record.overwork === 0" />
                </span>
                <span slot="description" slot-scope="id, record">
                  <template v-if="isConfirmedPage || !isMaster">
                    {{ record.description }}
                  </template>
                  <template v-else>
                    <a-input v-model="descriptions[record.id]" placeholder="自由入力可" />
                  </template>
                </span>
                <span slot="detail" slot-scope="id, record">
                  <a-button class="px-3" @click="onDetailInspection(record.id)">詳細</a-button>
                </span>
              </a-table>

              <p class="text-right" style="margin-top: 20px;">
                <span style="padding: 3px;" :class="$style.manual_row">この色の行は手動で作成したものです。</span>
              </p>
            </div>
          </div><!-- /.card-body (一覧) -->
        </div>
      </div>
    </div>

    <!-- 作成/編集/詳細モーダル -->
    <a-modal
      :title="modalText"
      :visible="modalVisible"
      :footer="isMaster ? undefined : null"
      @cancel="handleCancelInspectionModal"
    >
      <div>
        <a-form-model
          :model="inspectionForm"
          ref="inspectionFormRef"
          :rules="inspectionFormRules"
        >
          <a-form-model-item ref="inspection_date" label="点検日" prop="inspection_date">
            <a-config-provider v-if="inspectionCreate" :locale="locale">
              <a-date-picker v-model="inspectionForm.inspection_date" />
            </a-config-provider>
            <input v-else :value="inspectionForm.date | momentDate" readonly class="ant-input" />
          </a-form-model-item>

          <template v-if="inspectionCreate">
            <a-form-model-item  ref="create_date" label="申告日" prop="create_date">
              <a-config-provider :locale="locale">
                  <a-date-picker v-model="inspectionForm.create_date" />
              </a-config-provider>
            </a-form-model-item>
            <a-form-model-item ref="create_time" label='申告時刻' prop="create_time">
              <a-time-picker v-model="inspectionForm.create_time" />
            </a-form-model-item>
          </template>
          <template v-else>
            <a-form-model-item label="申告日時">
              <input :value="inspectionForm.create_date | momentDateTime" readonly class="ant-input" />
            </a-form-model-item>
          </template>

          <a-form-model-item label="運転者" prop="user_id">
            <a-select
              v-if="inspectionCreate"
              show-search
              v-model="inspectionForm.user_id"
              :filter-option="filterOption"
            >
              <a-select-option :key="0" :value="0">
                未選択
              </a-select-option>
              <a-select-option
                v-for="item in drivers"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <input v-else :value="inspectionForm.user_name" readonly class="ant-input" />
          </a-form-model-item>

          <a-form-model-item label="車両" prop="vehicle_id">
            <a-select
              v-if="inspectionCreate"
              show-search
              v-model="inspectionForm.vehicle_id"
              :filter-option="filterOption"
            >
              <a-select-option :key="0" :value="0">
                未選択
              </a-select-option>
              <a-select-option
                v-for="item in vehicles"
                :key="item.id"
                :value="item.id"
              >
                {{ item.branch_name}}: {{ item.name }}
              </a-select-option>
            </a-select>
            <input v-else :value="inspectionForm.vehicle_name" readonly class="ant-input" />
          </a-form-model-item>

          <a-form-model-item label="運転手備考" prop="driver_comment">
            <a-textarea
              v-if="isMaster"
              v-model="inspectionForm.driver_comment"
              placeholder="NGの理由等何かございましたら記入してください。"
              :auto-size="{ minRows: 3, maxRows: 5 }" />
            <textarea v-else v-model="inspectionForm.driver_comment" class="ant-input"
              style="height: 76px; min-height: 76px; max-height: 116px; overflow-y: hidden;"
              readonly />
          </a-form-model-item>

          <a-form-model-item label="過労申告" prop="overwork">
            <a-radio-group v-if="isMaster" v-model="inspectionForm.overwork" button-style="solid">
              <a-radio-button :value="0">OK</a-radio-button>
              <a-radio-button :value="1">NG</a-radio-button>
            </a-radio-group>
            <input v-else :value="inspectionForm.overwork === 0 ? 'OK' : 'NG'" readonly class="ant-input" />
          </a-form-model-item>

          <fieldset v-for="(category, idx) in inspectionItemsGroupByCategory" :key="idx" class="mt-4 border">
            <legend class="w-auto ml-4 mb-2 px-2 border-0">{{ category.name || '（未分類）' }}</legend>
            <div class="pl-4 pb-2">
              <div v-for="item in category.items" :key="item.id">
                <a-form-model-item :label="item.name" :prop="`answer.${item.id}`">
                  <div>
                    <a-radio-group v-if="isMaster" v-model="inspectionForm.answer[item.id]" button-style="solid">
                      <a-radio-button :value="1">OK</a-radio-button>
                      <a-radio-button :value="0">NG</a-radio-button>
                    </a-radio-group>
                    <input v-else :value="inspectionForm.answer[item.id] === 1 ? 'OK' : 'NG'" readonly class="ant-input" />
                  </div>
                  <div v-if="!!item.description" class="ml-2">
                    <div class="" style="cursor: pointer;"
                        @click="toggleItemDescriptionExpansion(item.id)">
                      <a-icon
                        type="right"
                        :rotate="!!itemDescriptionExpansionStatus[item.id] ? 90 : 0"
                      />
                      <span class="ml-2">説明</span>
                    </div>
                    <div
                      v-if="!!itemDescriptionExpansionStatus[item.id]"
                      class="pl-3 pr-4" style="white-space: pre-wrap; line-height: 1.5;"
                    >{{ item.description }}</div>
                  </div>
                </a-form-model-item>
              </div>
            </div>
          </fieldset>

          <a-checkbox
            v-if="isMaster"
            class="mt-4"
            :indeterminate="isAnswersIndeterminate"
            :checked="isAnswersAllOk"
            @change="onChangeAnswersCheck"
          >
            全ての項目をOK
          </a-checkbox>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelInspectionModal">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="inspectionConfirmLoading" @click="handleOkInspection" :class="$style.filledBtn">
              {{ modalText }}
            </a-button>
          </div>
          <div class="float-left" v-if="isDeletable">
            <a-button
              :class="$style.filledBtn" style="background-color: #EF5350 !important;"
              :loading="inspectionConfirmLoading"
              @click="handleDeleteInspection">
              削除
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
  @import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'
import CheckResult from './check-result.vue'
import { NotificationKey } from '@/store/notification'

export default {
  components: {
    CheckResult,
  },
  props: {
    isConfirmedPage: Boolean, // true:確認済一覧画面、false:未確認一覧画面
  },
  data() {
    return {
      locale: jaJa,
      targetDate: moment(), // 対象日 (検索条件)
      gotDate: '', // 一覧データを取得した日 (YYYY/MM/DD)
      gotBranchName: '', // 一覧データを取得した支店名
      loading: true,

      myRole: 3,
      branchId: null, // 選択された支店ID
      branchIdSelected: null, // 選択された支店ID (確定)
      branches: [], // 対象支店リスト
      drivers: [], // 対象ユーザーリスト
      vehicles: [], // 対象車両リスト
      inspectionItems: [], // 点検項目リスト
      inspectionIdsToConfirm: [], // 確認済みにするID
      descriptions: {}, // 未確認一覧の管理者コメント (key:点検ID)
      itemDescriptionExpansionStatus: {}, // 点検項目説明の展開状態 (key:点検項目ID, value:展開されているならばtrue)

      inspectionDataList: [], // 一覧データリスト
      modalText: '作成',
      modalVisible: false,
      inspectionConfirmLoading: false,
      inspectionForm: {
        inspection_date: moment(),
        create_date: moment(),
        create_time: moment(),
        vehicle_id: 0,
        user_id: 0,
        driver_comment: '',
        overwork: null,
        answer: {},
      },
      targetInspectionId: 0,
      isDeletable: false,
    }
  },
  computed: {
    pageTitle() {
      // eslint-disable-next-line no-irregular-whitespace
      return `運行前点検　${this.isConfirmedPage ? '確認済' : '未確認'}一覧`
    },
    gotDateHyphen() {
      return this.targetDate.format('YYYY-MM-DD')
    },
    // 支店管理者以上ならばtrue
    isMaster() {
      return this.myRole < 3
    },
    // 一覧ヘッダー
    columns() {
      return [
        (this.isConfirmedPage || !this.isMaster) ? null : {
          dataIndex: 'confirm',
          slots: { title: 'confirmTitle' },
          scopedSlots: { customRender: 'confirm' },
          sorter: false,
          width: 80,
        },
        {
          title: '支店名',
          dataIndex: 'branch_name',
          scopedSlots: { customRender: 'branch_name' },
          sorter: false,
          width: 120,
        },
        {
          dataIndex: 'driver',
          slots: { title: 'driverTitle' },
          scopedSlots: { customRender: 'driver' },
          sorter: false,
          width: 150,
        },
        {
          dataIndex: 'execution',
          slots: { title: 'executionTitle' },
          scopedSlots: { customRender: 'execution' },
          sorter: false,
          align: 'center',
          width: 60,
        },
        {
          dataIndex: 'answer',
          slots: { title: 'answerTitle' },
          scopedSlots: { customRender: 'answer' },
          sorter: false,
          align: 'center',
          width: 75,
        },
        {
          title: '過労',
          dataIndex: 'overwork',
          scopedSlots: { customRender: 'overwork' },
          sorter: false,
          align: 'center',
          width: 75,
        },
        {
          title: '運転手備考',
          dataIndex: 'driver_comment',
          scopedSlots: { customRender: 'driver_comment' },
          sorter: false,
          width: 300,
        },
        {
          title: '管理者コメント',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' },
          sorter: false,
        },
        this.isConfirmedPage ? {
          title: '管理者名',
          dataIndex: 'confirmer_name',
          sorter: false,
          width: 100,
        } : null,
        {
          title: '詳細',
          dataIndex: 'detail',
          scopedSlots: { customRender: 'detail' },
          sorter: false,
          align: 'center',
          width: 100,
        },
      ].filter(e => e != null)
    },
    // 「確認済みにする」ボタンがdisabledか？
    isConfirmationButtonDisabled() {
      return !this.inspectionIdsToConfirm.length
    },
    // テーブルヘッダーの確認チェックボックスが未確定か？
    isConfIndeterminate() {
      return this.inspectionIdsToConfirm.length > 0 &&
        this.unconfirmedInspectionIds.length > this.inspectionIdsToConfirm.length
    },
    // テーブルヘッダーの確認チェックボックスが全て選択されているか？
    isConfAllChecked() {
      return this.inspectionIdsToConfirm.length > 0 &&
        this.unconfirmedInspectionIds.length === this.inspectionIdsToConfirm.length
    },
    // 一覧行の確認チェックボックスがチェックされているか？
    isConfRowChecked() {
      return (id) => {
        return this.inspectionIdsToConfirm.findIndex((i) => i === id) >= 0
      }
    },
    // 一覧中の未確認のID
    unconfirmedInspectionIds() {
      return this.inspectionDataList
        .filter((r) => r.confirm === 0)
        .map((r) => r.id)
    },
    // true:作成モーダル, false:編集モーダル
    inspectionCreate() {
      return !this.targetInspectionId
    },
    // カテゴリでグルーピングした点検項目
    inspectionItemsGroupByCategory() {
      const groups = []
      for (const item of this.inspectionItems) {
        const categoryName = item.category || ''
        let group = groups.find(g => g.name === categoryName)
        if (!group) {
          group = {
            name: categoryName,
            items: [],
          }
          groups.push(group)
        }
        group.items.push(item)
      }
      return groups
    },
    // 作成/編集モーダルのバリデーションルール
    inspectionFormRules() {
      if (!this.isMaster) {
        return {}
      }

      const requiredRule = { required: true, message: '必須項目です。', trigger: 'change' }
      const requiredSelectRule = {
        type: 'number',
        min: 1,
        message: '必須項目です。',
        trigger: 'blur',
      }

      const rules = {
        overwork: [requiredRule],
      }
      if (this.inspectionCreate) {
        rules.inspection_date = [requiredRule]
        rules.create_date = [requiredRule]
        rules.create_time = [requiredRule]
        rules.user_id = [requiredSelectRule]
        rules.vehicle_id = [requiredSelectRule]
      }
      this.inspectionItems.forEach(item => {
        if (item.required_item === 1) {
          rules[`answer.${item.id}`] = [requiredRule]
        }
      })

      return rules
    },
    // 車両名から支店名を取得する
    branchNameByVehicleId() {
      return (vehicleId) => {
        const vehicle = this.vehicles.find(v => v.id === vehicleId)
        return (vehicle && vehicle.branch_name) || ''
      }
    },
    // 車両点検の全ての回答がOKならばtrueを返す
    isAnswerAllOk() {
      return (answer) => {
        // 0 (NG) が1つでも含まれていたらfalse
        return !Object.values(this.parseAnswer(answer)).includes(0)
      }
    },
    // 作成/編集モーダルの過労申告・回答のチェックボックスが未確定か？
    isAnswersIndeterminate() {
      if (this.inspectionForm.overwork === 0) {
        if (this.inspectionItems.every(item => this.inspectionForm.answer[item.id] === 1)) {
          // 全てOK
          return false
        }
      }
      if (this.inspectionForm.overwork === 1) {
        if (this.inspectionItems.every(item => this.inspectionForm.answer[item.id] === 0)) {
          // 全てNG
          return false
        }
      }
      if (this.inspectionForm.overwork == null) {
        if (this.inspectionItems.every(item => this.inspectionForm.answer[item.id] == null)) {
          // 全て未選択
          return false
        }
      }
      return true
    },
    // 作成/編集モーダルの過労申告・回答のチェックボックスが全てOKか？
    isAnswersAllOk() {
      if (this.inspectionForm.overwork === 0) {
        if (this.inspectionItems.every(item => this.inspectionForm.answer[item.id] === 1)) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    branches: function(val) {
      if (val.length >= 1) this.refreshList()
    },
    branchIdSelected: function(id) {
      Vue.prototype.$api.send('get', `vehicles/list/${id}/open`)
        .then(response => {
          this.vehicles = response.map((item) => {
            if (this.concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
            return item
          })
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車両の取得に失敗しました。',
          })
        })
    },
  },
  created() {
    if (this.$route.query.date) {
      this.targetDate = moment(this.$route.query.date)
    }

    Vue.prototype.$api.send('get', 'user/branches')
      .then(response => {
        // 先頭に「全社」を追加
        this.branches = [{ id: 0, name: '全社' }].concat(response)
        this.branchId = Number(this.$route.query.branchId) || this.branches[0].id
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    Vue.prototype.$api.send('get', 'inspection_items')
      .then(response => {
        this.inspectionItems = response
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 運行前点検項目の取得に失敗しました。',
        })
      })

    Vue.prototype.$api.send('get', 'user')
      .then(myResponse => {
        this.myRole = myResponse.role
        this.concatVehicleNameAndNo = myResponse.concat_vehicle_name_and_no
        if (myResponse.role !== 3) {
          Vue.prototype.$api.send('get', 'users')
            .then(response => {
              this.drivers = response
            })
            .catch(error => {
              this.$notification['error']({
                message: error.status + ': ユーザーの取得に失敗しました。',
              })
            })
        } else {
          this.drivers = [myResponse]
        }
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
      })

    this.updateNotification()
  },
  filters: {
    momentDateTime: function (datetime) {
      return datetime ? moment(datetime).format('YYYY/MM/DD HH:mm:ss') : ''
    },
    momentDate: function (date) {
      return moment(date).format('YYYY/MM/DD')
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    yesterday() { this.targetDate = moment(this.targetDate).subtract(1, 'days') },
    today() { this.targetDate = moment() },
    tomorrow() { this.targetDate = moment(this.targetDate).add(1, 'days') },
    // テーブルヘッダーの確認チェックボックス変更時
    onChangeConfirmAll(e) {
      this.inspectionIdsToConfirm = e.target.checked
        // すべてチェックON (unconfirmedInspectionIdsを複製)
        ? this.unconfirmedInspectionIds.slice(0, this.unconfirmedInspectionIds.length)
        // すべてチェックOFF
        : []
    },
    // 一覧行の確認チェックボックス変更時
    onChangeConfirmRow(e, id) {
      if (e.target.checked) {
        this.inspectionIdsToConfirm.push(id)
      } else {
        // チェックOFF: inspectionIdsToConfirmから削除
        const index = this.inspectionIdsToConfirm.findIndex((i) => i === id)
        if (index >= 0) {
          this.inspectionIdsToConfirm.splice(index, 1)
        }
      }
    },
    // 一覧データ取得 (共通)
    refreshList() {
      this.loading = true
      this.branchIdSelected = this.branchId

      Vue.prototype.$api.send(
        'get',
        this.isConfirmedPage ? 'inspections/confirmed' : 'inspections/unconfirmed',
        {
          date: this.gotDateHyphen,
          branch_id: this.branchId,
        },
      )
        .then(response => {
          this.inspectionDataList = response.map((item) => {
            if (this.concatVehicleNameAndNo && item.number) item.vehicle_name = item.vehicle_name + ',' + item.number
            return item
          })
          this.inspectionIdsToConfirm = []

          if (!this.isConfirmedPage) {
            this.descriptions = response.reduce((o, item) => {
              o[item.id] = item.description || ''
              return o
            }, {})
          }
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 運行前点検の取得に失敗しました。',
          })
          this.inspectionDataList = []
        })
        .finally(() => {
          this.gotDate = this.targetDate.format('YYYY/MM/DD')
          this.gotBranchName = this.branches.find(e => e.id === this.branchId).name
          this.loading = false
        })
    },
    // 一覧の上の「確認済みにする」ボタンクリック時
    turnSelectedInspectionsConfirmed() {
      this.loading = true
      const inspections = this.inspectionIdsToConfirm.reduce((o, id) => {
        o[id] = this.descriptions[id] || ''
        return o
      }, {})

      Vue.prototype.$api.send(
        'post',
        'inspections/confirm',
        { inspections },
      )
        .then(_response => {
          this.refreshList()
          this.updateNotification()
          this.$notification['success']({
            message: '運行前点検を更新しました。',
          })
        })
        .catch(error => {
          if (error.status === 405) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else {
            this.$notification['error']({
              message: error.status + ': 運行前点検の更新に失敗しました。',
            })
          }
          this.loading = false
        })
    },
    // 作成/編集モーダルのキャンセルボタンクリック時
    handleCancelInspectionModal() { this.modalVisible = false },
    // 一覧の上の作成ボタンクリック時
    onCreateInspection() {
      const now = moment()
      this.targetInspectionId = 0
      this.inspectionForm.inspection_date = now
      this.inspectionForm.create_date = now
      this.inspectionForm.create_time = now
      this.inspectionForm.vehicle_id = 0
      this.inspectionForm.user_id = 0
      this.inspectionForm.driver_comment = ''
      this.inspectionForm.overwork = null
      this.inspectionForm.answer = {}
      this.modalText = '作成'
      this.isDeletable = false
      this.itemDescriptionExpansionStatus = {}
      this.clearValidate()
      this.modalVisible = true
    },
    // 一覧行の「詳細」ボタンクリック時
    onDetailInspection(inspectionId) {
      this.inspectionConfirmLoading = true
      Vue.prototype.$api.send('get', `inspections/${inspectionId}/show`)
        .then(response => {
          this.targetInspectionId = response.id
          this.inspectionForm.inspection_date = moment(response.date)
          this.inspectionForm.create_date = moment(response.created_at)
          this.inspectionForm.create_time = moment(response.created_at)
          this.inspectionForm.vehicle_id = response.vehicle_id
          this.inspectionForm.vehicle_name = response.vehicle_name
          this.inspectionForm.user_id = response.user_id
          this.inspectionForm.user_name = response.user_name
          this.inspectionForm.driver_comment = response.driver_comment
          this.inspectionForm.overwork = response.overwork
          this.inspectionForm.answer = this.parseAnswer(response.answer)
          this.modalText = this.isMaster ? '更新' : '詳細'
          this.isDeletable = true
          this.itemDescriptionExpansionStatus = {}
          this.clearValidate()
          this.modalVisible = true
        })
        .catch(error => {
          if (error.status === 405) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else {
            this.$notification['error']({
              message: error.status + ': 運行前点検の取得に失敗しました。',
            })
          }
        })
        .finally(() => {
          this.inspectionConfirmLoading = false
        })
    },
    // 編集モーダルの削除ボタンクリック時
    handleDeleteInspection() {
      this.inspectionConfirmLoading = true
      Vue.prototype.$api.send('delete', 'inspections', { id: this.targetInspectionId })
        .then(_response => {
          this.$notification['success']({
            message: '運行前点検を削除しました。',
          })
          this.inspectionDataList = this.inspectionDataList.filter(e => e.id !== this.targetInspectionId)
          this.inspectionIdsToConfirm = this.inspectionIdsToConfirm.filter(i => i !== this.targetInspectionId)
          this.updateNotification()
          this.modalVisible = false
        })
        .catch(error => {
          if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else if (error.data) {
            for (const key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          console.error(error)
        })
        .finally(() => {
          this.inspectionConfirmLoading = false
        })
    },
    // 作成/編集モーダルの作成/更新ボタンクリック時
    handleOkInspection() {
      this.clearValidate()
      this.$refs.inspectionFormRef.validate(valid => {
        if (!valid) return

        this.inspectionConfirmLoading = true
        const actionType = this.inspectionCreate ? 'post' : 'put'
        const actionUrl = this.inspectionCreate ? 'inspections/manual' : 'inspections'
        const bodyData = {
          vehicle_id: this.inspectionForm.vehicle_id,
          user_id: this.inspectionForm.user_id,
          driver_comment: this.inspectionForm.driver_comment,
          overwork: this.inspectionForm.overwork,
          answer: this.inspectionForm.answer,
        }

        if (this.inspectionCreate) {
          bodyData.date = this.inspectionForm.inspection_date.format('YYYY-MM-DD')
          bodyData.created_at = this.inspectionForm.create_date.format('YYYY-MM-DD') +
            ' ' + this.inspectionForm.create_time.format('HH:mm:ss')
        } else {
          bodyData.id = this.targetInspectionId
        }

        Vue.prototype.$api.send(actionType, actionUrl, bodyData)
          .then(_response => {
            this.$notification['success']({
              message: `運行前点検を${this.modalText}しました。`,
            })

            this.refreshList()
            this.updateNotification()
            this.modalVisible = false
          })
          .catch(error => {
            if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
              this.$notification['error']({
                message: error.data.data,
              })
            } else if (error.data) {
              for (const key in error.data.errors) {
                this.$refs[key].validateState = 'error'
                this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            console.error(error)
          })
          .finally(() => {
            this.inspectionConfirmLoading = false
          })
      })
    },
    // 作成/編集モーダルのバリデーション結果をリセットする
    clearValidate() {
      try {
        this.$refs.inspectionFormRef.clearValidate()
      } catch (_error) {
        // ignore
      }
    },
    // 作成/編集モーダルの点検項目の説明の展開状態を切り替える
    toggleItemDescriptionExpansion(itemId) {
      this.$set(this.itemDescriptionExpansionStatus, itemId, !this.itemDescriptionExpansionStatus[itemId])
    },
    // 作成/編集モーダルの過労申告・回答の「全ての項目をOK」のチェックボックス変更時
    onChangeAnswersCheck(e) {
      this.inspectionForm.overwork = e.target.checked ? 0 : null
      const answer = {}
      if (e.target.checked) {
        for (const item of this.inspectionItems) {
          answer[item.id] = 1
        }
      }
      this.inspectionForm.answer = answer
    },
    // レスポンスのanswerをオブジェクトに変換する
    parseAnswer(answer) {
      if (!answer) return {}
      // answerは "{'1':1,'3':0,'5':1,}" のような文字列
      return JSON.parse(answer.replaceAll(',}', '}').replaceAll("'", '"'))
    },
    // メニュー項目の通知バッジを更新する
    updateNotification() {
      if (!this.isConfirmedPage) {
        this.$store.dispatch('notification/fetch', NotificationKey.INSPECTION)
      }
    },
  },
}
</script>
