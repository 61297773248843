var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { class: _vm.$style.pageTitle }, [
        _vm._v(_vm._s(_vm.pageTitle))
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "a-form",
                  {
                    staticClass: "no-print",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { layout: "inline" }
                  },
                  [
                    _vm._v("\n            対象支店：\n            "),
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "30%", "margin-right": "20px" },
                        attrs: {
                          "show-search": "",
                          "filter-option": _vm.filterOption
                        },
                        model: {
                          value: _vm.branchId,
                          callback: function($$v) {
                            _vm.branchId = $$v
                          },
                          expression: "branchId"
                        }
                      },
                      _vm._l(_vm.branches, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.name) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v("\n            対象日：\n            "),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "3px" },
                        on: {
                          click: function($event) {
                            return _vm.yesterday()
                          }
                        }
                      },
                      [_vm._v("前日")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "3px" },
                        on: {
                          click: function($event) {
                            return _vm.today()
                          }
                        }
                      },
                      [_vm._v("今日")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "7px" },
                        on: {
                          click: function($event) {
                            return _vm.tomorrow()
                          }
                        }
                      },
                      [_vm._v("翌日")]
                    ),
                    _c(
                      "a-config-provider",
                      { attrs: { locale: _vm.locale } },
                      [
                        _c("a-date-picker", {
                          attrs: { placeholder: "対象日" },
                          model: {
                            value: _vm.targetDate,
                            callback: function($$v) {
                              _vm.targetDate = $$v
                            },
                            expression: "targetDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.refreshList }
                      },
                      [_vm._v("取得")]
                    ),
                    _c(
                      "span",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/inspection/summary",
                                query: {
                                  branchId: _vm.branchId,
                                  date: _vm.gotDateHyphen
                                }
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                class: _vm.$style.filledBtn,
                                attrs: { type: "primary" }
                              },
                              [_vm._v("月別点検状況")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("hr", { staticClass: "mt-4" })
              ],
              1
            ),
            _c("div", { staticClass: "card-body" }, [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("h4", [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.gotBranchName))
                        ]),
                        _vm._v(" の運行前点検")
                      ]),
                      _c("div", { staticClass: "mt-4 clearfix" }, [
                        _c(
                          "div",
                          { staticClass: "float-left" },
                          [
                            _vm.isConfirmedPage
                              ? [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path: "/inspection/unconfirmed",
                                          query: {
                                            branchId: _vm.branchId,
                                            date: _vm.gotDateHyphen
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          class: _vm.$style.filledBtn,
                                          attrs: { type: "primary" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      未確認一覧\n                    "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  _vm.isMaster
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "mr-2",
                                          class: _vm.$style.filledBtn,
                                          attrs: {
                                            type: "primary",
                                            disabled:
                                              _vm.isConfirmationButtonDisabled
                                          },
                                          on: {
                                            click:
                                              _vm.turnSelectedInspectionsConfirmed
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    確認済みにする\n                  "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path: "/inspection/confirmed",
                                          query: {
                                            branchId: _vm.branchId,
                                            date: _vm.gotDateHyphen
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          class: _vm.$style.filledBtn,
                                          attrs: { type: "primary" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      確認履歴\n                    "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm.isMaster
                          ? _c(
                              "div",
                              { staticClass: "float-right" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    class: _vm.$style.filledBtn,
                                    attrs: { type: "primary" },
                                    on: { click: _vm.onCreateInspection }
                                  },
                                  [_vm._v("作成")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "a-table",
                        {
                          staticClass: "mt-4",
                          class: _vm.$style.aTable,
                          attrs: {
                            columns: _vm.columns,
                            "data-source": _vm.inspectionDataList,
                            pagination: false,
                            bordered: false,
                            rowKey: "id",
                            rowClassName: function(r, i) {
                              return i % 2 === 0 && r.manual === 1
                                ? _vm.$style.manual_row +
                                    " " +
                                    _vm.$style.stripe
                                : i % 2 === 0
                                ? _vm.$style.stripe
                                : r.manual === 1
                                ? _vm.$style.manual_row
                                : ""
                            },
                            scroll: { x: true }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "confirm",
                                fn: function(id, record) {
                                  return !_vm.isConfirmedPage
                                    ? _c("span", {}, [
                                        record.confirm === 1
                                          ? _c("span", [_vm._v("確認済")])
                                          : _c(
                                              "div",
                                              [
                                                _c("a-checkbox", {
                                                  attrs: {
                                                    checked: _vm.isConfRowChecked(
                                                      record.id
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.onChangeConfirmRow(
                                                        $event,
                                                        record.id
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                      ])
                                    : _vm._e()
                                }
                              },
                              {
                                key: "branch_name",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.branchNameByVehicleId(
                                            record.vehicle_id
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "driver",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(record.user_name) +
                                        " (" +
                                        _vm._s(record.vehicle_name) +
                                        ")\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "execution",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          record.execution === 1 ? "済" : "無"
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "answer",
                                fn: function(id, record) {
                                  return _c(
                                    "span",
                                    {},
                                    [
                                      record.execution
                                        ? _c("check-result", {
                                            attrs: {
                                              "is-ok": _vm.isAnswerAllOk(
                                                record.answer
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              },
                              {
                                key: "overwork",
                                fn: function(id, record) {
                                  return _c(
                                    "span",
                                    {},
                                    [
                                      record.execution
                                        ? _c("check-result", {
                                            attrs: {
                                              "is-ok": record.overwork === 0
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              },
                              {
                                key: "description",
                                fn: function(id, record) {
                                  return _c(
                                    "span",
                                    {},
                                    [
                                      _vm.isConfirmedPage || !_vm.isMaster
                                        ? [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(record.description) +
                                                "\n                "
                                            )
                                          ]
                                        : [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "自由入力可"
                                              },
                                              model: {
                                                value:
                                                  _vm.descriptions[record.id],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.descriptions,
                                                    record.id,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "descriptions[record.id]"
                                              }
                                            })
                                          ]
                                    ],
                                    2
                                  )
                                }
                              },
                              {
                                key: "detail",
                                fn: function(id, record) {
                                  return _c(
                                    "span",
                                    {},
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticClass: "px-3",
                                          on: {
                                            click: function($event) {
                                              return _vm.onDetailInspection(
                                                record.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("詳細")]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "confirmTitle" },
                              slot: "confirmTitle"
                            },
                            [
                              _c(
                                "a-checkbox",
                                {
                                  attrs: {
                                    indeterminate: _vm.isConfIndeterminate,
                                    checked: _vm.isConfAllChecked
                                  },
                                  on: { change: _vm.onChangeConfirmAll }
                                },
                                [
                                  _vm._v(
                                    "\n                  確認\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              attrs: { slot: "driverTitle" },
                              slot: "driverTitle"
                            },
                            [
                              _c("div", { staticClass: "mb-1" }, [
                                _vm._v("運転手名")
                              ]),
                              _c("div", [_vm._v("(車両名)")])
                            ]
                          ),
                          _c(
                            "span",
                            {
                              attrs: { slot: "executionTitle" },
                              slot: "executionTitle"
                            },
                            [
                              _c("div", { staticClass: "mb-1" }, [
                                _vm._v("実施")
                              ]),
                              _c("div", [_vm._v("有無")])
                            ]
                          ),
                          _c(
                            "span",
                            {
                              attrs: { slot: "answerTitle" },
                              slot: "answerTitle"
                            },
                            [
                              _c("div", { staticClass: "mb-1" }, [
                                _vm._v("車両")
                              ]),
                              _c("div", [_vm._v("点検")])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "text-right",
                          staticStyle: { "margin-top": "20px" }
                        },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.$style.manual_row,
                              staticStyle: { padding: "3px" }
                            },
                            [_vm._v("この色の行は手動で作成したものです。")]
                          )
                        ]
                      )
                    ],
                    1
                  )
            ])
          ])
        ])
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalText,
            visible: _vm.modalVisible,
            footer: _vm.isMaster ? undefined : null
          },
          on: { cancel: _vm.handleCancelInspectionModal }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "inspectionFormRef",
                  attrs: {
                    model: _vm.inspectionForm,
                    rules: _vm.inspectionFormRules
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "inspection_date",
                      attrs: { label: "点検日", prop: "inspection_date" }
                    },
                    [
                      _vm.inspectionCreate
                        ? _c(
                            "a-config-provider",
                            { attrs: { locale: _vm.locale } },
                            [
                              _c("a-date-picker", {
                                model: {
                                  value: _vm.inspectionForm.inspection_date,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.inspectionForm,
                                      "inspection_date",
                                      $$v
                                    )
                                  },
                                  expression: "inspectionForm.inspection_date"
                                }
                              })
                            ],
                            1
                          )
                        : _c("input", {
                            staticClass: "ant-input",
                            attrs: { readonly: "" },
                            domProps: {
                              value: _vm._f("momentDate")(
                                _vm.inspectionForm.date
                              )
                            }
                          })
                    ],
                    1
                  ),
                  _vm.inspectionCreate
                    ? [
                        _c(
                          "a-form-model-item",
                          {
                            ref: "create_date",
                            attrs: { label: "申告日", prop: "create_date" }
                          },
                          [
                            _c(
                              "a-config-provider",
                              { attrs: { locale: _vm.locale } },
                              [
                                _c("a-date-picker", {
                                  model: {
                                    value: _vm.inspectionForm.create_date,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.inspectionForm,
                                        "create_date",
                                        $$v
                                      )
                                    },
                                    expression: "inspectionForm.create_date"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "create_time",
                            attrs: { label: "申告時刻", prop: "create_time" }
                          },
                          [
                            _c("a-time-picker", {
                              model: {
                                value: _vm.inspectionForm.create_time,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.inspectionForm,
                                    "create_time",
                                    $$v
                                  )
                                },
                                expression: "inspectionForm.create_time"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "申告日時" } },
                          [
                            _c("input", {
                              staticClass: "ant-input",
                              attrs: { readonly: "" },
                              domProps: {
                                value: _vm._f("momentDateTime")(
                                  _vm.inspectionForm.create_date
                                )
                              }
                            })
                          ]
                        )
                      ],
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "運転者", prop: "user_id" } },
                    [
                      _vm.inspectionCreate
                        ? _c(
                            "a-select",
                            {
                              attrs: {
                                "show-search": "",
                                "filter-option": _vm.filterOption
                              },
                              model: {
                                value: _vm.inspectionForm.user_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.inspectionForm, "user_id", $$v)
                                },
                                expression: "inspectionForm.user_id"
                              }
                            },
                            [
                              _c(
                                "a-select-option",
                                { key: 0, attrs: { value: 0 } },
                                [_vm._v("\n              未選択\n            ")]
                              ),
                              _vm._l(_vm.drivers, function(item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.id, attrs: { value: item.id } },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.name) +
                                        "\n            "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _c("input", {
                            staticClass: "ant-input",
                            attrs: { readonly: "" },
                            domProps: { value: _vm.inspectionForm.user_name }
                          })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "車両", prop: "vehicle_id" } },
                    [
                      _vm.inspectionCreate
                        ? _c(
                            "a-select",
                            {
                              attrs: {
                                "show-search": "",
                                "filter-option": _vm.filterOption
                              },
                              model: {
                                value: _vm.inspectionForm.vehicle_id,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.inspectionForm,
                                    "vehicle_id",
                                    $$v
                                  )
                                },
                                expression: "inspectionForm.vehicle_id"
                              }
                            },
                            [
                              _c(
                                "a-select-option",
                                { key: 0, attrs: { value: 0 } },
                                [_vm._v("\n              未選択\n            ")]
                              ),
                              _vm._l(_vm.vehicles, function(item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.id, attrs: { value: item.id } },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.branch_name) +
                                        ": " +
                                        _vm._s(item.name) +
                                        "\n            "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _c("input", {
                            staticClass: "ant-input",
                            attrs: { readonly: "" },
                            domProps: { value: _vm.inspectionForm.vehicle_name }
                          })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "運転手備考", prop: "driver_comment" } },
                    [
                      _vm.isMaster
                        ? _c("a-textarea", {
                            attrs: {
                              placeholder:
                                "NGの理由等何かございましたら記入してください。",
                              "auto-size": { minRows: 3, maxRows: 5 }
                            },
                            model: {
                              value: _vm.inspectionForm.driver_comment,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.inspectionForm,
                                  "driver_comment",
                                  $$v
                                )
                              },
                              expression: "inspectionForm.driver_comment"
                            }
                          })
                        : _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.inspectionForm.driver_comment,
                                expression: "inspectionForm.driver_comment"
                              }
                            ],
                            staticClass: "ant-input",
                            staticStyle: {
                              height: "76px",
                              "min-height": "76px",
                              "max-height": "116px",
                              "overflow-y": "hidden"
                            },
                            attrs: { readonly: "" },
                            domProps: {
                              value: _vm.inspectionForm.driver_comment
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.inspectionForm,
                                  "driver_comment",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "過労申告", prop: "overwork" } },
                    [
                      _vm.isMaster
                        ? _c(
                            "a-radio-group",
                            {
                              attrs: { "button-style": "solid" },
                              model: {
                                value: _vm.inspectionForm.overwork,
                                callback: function($$v) {
                                  _vm.$set(_vm.inspectionForm, "overwork", $$v)
                                },
                                expression: "inspectionForm.overwork"
                              }
                            },
                            [
                              _c("a-radio-button", { attrs: { value: 0 } }, [
                                _vm._v("OK")
                              ]),
                              _c("a-radio-button", { attrs: { value: 1 } }, [
                                _vm._v("NG")
                              ])
                            ],
                            1
                          )
                        : _c("input", {
                            staticClass: "ant-input",
                            attrs: { readonly: "" },
                            domProps: {
                              value:
                                _vm.inspectionForm.overwork === 0 ? "OK" : "NG"
                            }
                          })
                    ],
                    1
                  ),
                  _vm._l(_vm.inspectionItemsGroupByCategory, function(
                    category,
                    idx
                  ) {
                    return _c(
                      "fieldset",
                      { key: idx, staticClass: "mt-4 border" },
                      [
                        _c(
                          "legend",
                          { staticClass: "w-auto ml-4 mb-2 px-2 border-0" },
                          [_vm._v(_vm._s(category.name || "（未分類）"))]
                        ),
                        _c(
                          "div",
                          { staticClass: "pl-4 pb-2" },
                          _vm._l(category.items, function(item) {
                            return _c(
                              "div",
                              { key: item.id },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: item.name,
                                      prop: "answer." + item.id
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm.isMaster
                                          ? _c(
                                              "a-radio-group",
                                              {
                                                attrs: {
                                                  "button-style": "solid"
                                                },
                                                model: {
                                                  value:
                                                    _vm.inspectionForm.answer[
                                                      item.id
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.inspectionForm.answer,
                                                      item.id,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "inspectionForm.answer[item.id]"
                                                }
                                              },
                                              [
                                                _c(
                                                  "a-radio-button",
                                                  { attrs: { value: 1 } },
                                                  [_vm._v("OK")]
                                                ),
                                                _c(
                                                  "a-radio-button",
                                                  { attrs: { value: 0 } },
                                                  [_vm._v("NG")]
                                                )
                                              ],
                                              1
                                            )
                                          : _c("input", {
                                              staticClass: "ant-input",
                                              attrs: { readonly: "" },
                                              domProps: {
                                                value:
                                                  _vm.inspectionForm.answer[
                                                    item.id
                                                  ] === 1
                                                    ? "OK"
                                                    : "NG"
                                              }
                                            })
                                      ],
                                      1
                                    ),
                                    !!item.description
                                      ? _c("div", { staticClass: "ml-2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleItemDescriptionExpansion(
                                                    item.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: {
                                                  type: "right",
                                                  rotate: !!_vm
                                                    .itemDescriptionExpansionStatus[
                                                    item.id
                                                  ]
                                                    ? 90
                                                    : 0
                                                }
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [_vm._v("説明")]
                                              )
                                            ],
                                            1
                                          ),
                                          !!_vm.itemDescriptionExpansionStatus[
                                            item.id
                                          ]
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "pl-3 pr-4",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    "line-height": "1.5"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.description)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    )
                  }),
                  _vm.isMaster
                    ? _c(
                        "a-checkbox",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            indeterminate: _vm.isAnswersIndeterminate,
                            checked: _vm.isAnswersAllOk
                          },
                          on: { change: _vm.onChangeAnswersCheck }
                        },
                        [_vm._v("\n          全ての項目をOK\n        ")]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelInspectionModal }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      class: _vm.$style.filledBtn,
                      attrs: {
                        type: "primary",
                        loading: _vm.inspectionConfirmLoading
                      },
                      on: { click: _vm.handleOkInspection }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.modalText) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.isDeletable
                ? _c(
                    "div",
                    { staticClass: "float-left" },
                    [
                      _c(
                        "a-button",
                        {
                          class: _vm.$style.filledBtn,
                          staticStyle: {
                            "background-color": "#EF5350 !important"
                          },
                          attrs: { loading: _vm.inspectionConfirmLoading },
                          on: { click: _vm.handleDeleteInspection }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }